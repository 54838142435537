.App {
  text-align: center;
  background-color: #ffffff;
  min-height: 100vh;
  background-image: url(../src/assets/back5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.home {
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.background {
  position: absolute;
  right: -5%;
  top: 0;
  height: 100vh;
  overflow: hidden;
}
.back-img {
  height: 100%;
}
.nav {
  display: flex;
  padding: 0 3%;
  height: 15%;
  justify-content: space-between;
  align-items: center;
}
.company {
  display: flex;
}
.nav-img {
  height: 45px;
  margin-top: 7px;
}
.name {
  margin-left: 7px;
  font-size: 45px;
  font-family: "Audiowide", sans-serif;
  color: rgb(84, 25, 25);
}
.moto {
  font-weight: 500;
  font-family: monospace;
  margin-top: -10px;
  margin-left: 3px;
}
.ant-btn-primary {
  background-color: rgb(84, 25, 25);
}
.contact {
  letter-spacing: 6px;
  margin-top: 10px;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}
h4 {
  margin: 2px;
  font-weight: 400;
}
p {
  margin: 5px;
}
.bottom {
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon {
  width: 50%;
}
.start {
  text-align: start;
}
.address {
  width: 50%;
  display: flex;
  justify-content: center;
}
.street {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.locationdot {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.temp {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.App-header {
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(84, 25, 25);
}
.social {
  width: 35%;
  display: flex;
  justify-content: space-around;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
